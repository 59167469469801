<template>
  <v-dialog width="800" id="product-image-picker-modal" class="product-attachment-modal" :value="showDialog">
    <v-card>
      <v-card-title class="headline">
        <span><i class="fa fa-edit"></i> Изменение статуса заказа</span>
      </v-card-title>

      <v-card-text>
        <form class="m-form m-form--state">
          <div>
            <v-select
                v-model="status"
                :items="availableStatuses"
                item-value="key"
                item-text="name"
                label="Изменить статус на *"
                hide-details="auto"
                required
            ></v-select>
          </div>
        </form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <CButton class="mr-3" color="secondary" @click="$emit('close')">Отмена</CButton>
        <CButton :disabled="!status" @click="submit" color="success"><i class="fa fa-arrow-right"></i> <span>Изменить статус</span></CButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { serialize } from 'object-to-formdata';
import api from "@/api";
import {showMessage} from "@/lib/toasted";
import EntityModalMixin from "@/views/grid_elements/mixins/EntityModalMixin";
import {mapGetters} from "vuex";

export default {
  name: "ChangeOrderStatusModal",
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    orderType: {
      type: String,
      default: 'dealer'
    }
  },
  mixins: [EntityModalMixin],
  async mounted() {
    await this.fetchStatuses();
  },
  data() {
    return {
      status: null,
      statuses: []
    }
  },
  methods: {
    async fetchStatuses() {
      try {
        const {
          data: {data: {statuses}}
        } = await api.orders.getAvailableStatusesToChange(this.currentItem.id);

        this.statuses = statuses;
      } catch (e) {
        console.log(e);
      }
    },
    async submit() {
      const method = this.orderType === 'dealer' ? api.orders.changeDealerOrderStatus : api.orders.changeSubdivisionOrderStatus;
      const {data: {status, message}} = await method(this.currentItem.id, this.status);

      showMessage(status, message);

      if (status === 'ok') {
        this.$emit('stored');
      }
    }
  },
  computed: {
    ...mapGetters('orders', ['getProductOrderStatusNameByKey']),
    availableStatuses() {
      const that = this;

      return this.statuses.map((status) => ({
        key: status,
        name: that.getProductOrderStatusNameByKey(status)
      }))
    }
  }
}
</script>

<style scoped>

</style>
