<template>
  <ValidationObserver slim v-slot="{ invalid, untouched, handleSubmit }">
    <v-dialog width="800" id="product-image-picker-modal" class="product-attachment-modal" :value="showDialog">
      <v-card>
        <v-card-title class="headline">
          <span><i class="fa fa-edit"></i> Подтверждение корректировки заказа № {{ orderType == 'dealer' ? currentItem.number : currentItem.product_order_number }}</span>
        </v-card-title>

        <v-card-text>
          <form class="m-form m-form--state">
            <div>
              <ValidationProvider name="name" rules="required|max:300" v-slot="{invalid, validated}">
                <v-textarea
                    label="Причина  *"
                    v-model="order_edit_reason"
                    :error="invalid && validated"
                ></v-textarea>
              </ValidationProvider>
            </div>
          </form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <CButton class="mr-3" color="secondary" @click="$emit('close')">Отмена</CButton>
          <CButton :disabled="invalid" @click="handleSubmit(submit)" color="success">
            <span>Подтвердить</span></CButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </ValidationObserver>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import api from "@/api";
import {showMessage} from "@/lib/toasted";
import EntityModalMixin from "@/views/grid_elements/mixins/EntityModalMixin";
import {mapGetters} from "vuex";

export default {
  name: "ApproveOrderPositionDeleting",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    orderType: {
      type: String,
      default: 'dealer'
    },
  },
  mixins: [EntityModalMixin],
  async mounted() {
  },
  data() {
    return {
      status: null,
      statuses: [],
      order_edit_reason: ''
    }
  },
  methods: {
    async submit() {
      const {data: {status, message}} = await api.orders.deleteProductOrderPosition(this.currentItem.id, {positions: this.currentItem.deletedItems, reason: this.order_edit_reason}, this.orderType);

      showMessage(status, message);

      if (status === 'ok') {
        this.$emit('stored');
      }
    }
  },
  computed: {
    ...mapGetters('orders', ['getProductOrderStatusNameByKey']),
    availableStatuses() {
      const that = this;

      return this.statuses.map((status) => ({
        key: status,
        name: that.getProductOrderStatusNameByKey(status)
      }))
    }
  }
}
</script>

<style scoped>

</style>
